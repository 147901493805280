import { forwardRef, ReactNode } from "react";
import { Heading, Text } from "@otrium/atoms";
import { Flex } from "@otrium/core";
import { Trans } from "@lingui/macro";
import { Button } from "src/atoms/Button";
import {
  OptionRow,
  ShoppingCartIcon,
  SizeGuideButton,
  SizeGuideText,
  Wrapper,
} from "./MobileSizeOverlay.styled";
import { CloseButton } from "src/atoms/CloseButton";
import { useTheme, Theme } from "@emotion/react";

interface Props {
  onClose: () => void;
  openSizeGuide?: () => void;
  hasSizeGuide: boolean;
  addToCartLoading: boolean;
  handleAddToCart: () => void;
  options: { value: string; text: ReactNode; description: ReactNode | null }[];
  selected?: string;
  handleOption: (e: null, option: { value: string }) => void;
  withMobileOverlay?: boolean;
}

const MobileSizeOverlay = forwardRef<HTMLDivElement, Props>(
  (
    {
      onClose,
      openSizeGuide,
      hasSizeGuide,
      addToCartLoading,
      handleAddToCart,
      options,
      selected,
      handleOption,
      withMobileOverlay = false,
    },
    ref
  ) => {
    const theme: Theme = useTheme();
    const onOptionClick = (option: { value: string }) => () =>
      handleOption(null, option);

    return (
      <Wrapper ref={ref} withMobileOverlay={withMobileOverlay}>
        <Heading
          as="h4"
          fontFamily="PlusJakartaSans"
          fontSize={3}
          fontWeight={700}
          lineHeight={"32px"}
          color="tone.black"
        >
          <Trans>Select your size</Trans>
        </Heading>
        <CloseButton
          fill={theme.colors.tone.silver}
          iconSize={24}
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 16,
            top: 20,
            width: "auto",
            height: "auto",
          }}
        />
        <Flex my={2}>
          <SizeGuideText>
            <Trans>Not sure about your size?</Trans>
          </SizeGuideText>
          {hasSizeGuide && (
            <SizeGuideButton onClick={openSizeGuide} ml={1}>
              <Trans>View size guide</Trans>
            </SizeGuideButton>
          )}
        </Flex>
        {options.map((option) => (
          <OptionRow
            key={`size-${option.value}`}
            px={2}
            selected={option.value === selected}
            onClick={onOptionClick(option)}
          >
            {option.text}
            <Text
              fontSize={0}
              color="tone.anthraciteLight"
              lineHeight={"17.6px"}
            >
              {option.description}
            </Text>
          </OptionRow>
        ))}
        <Flex mt={2} justifyContent="center">
          {!addToCartLoading ? (
            <Button
              variant="primary"
              onClick={handleAddToCart}
              disabled={selected === undefined}
              leftIcon={<ShoppingCartIcon />}
              fullWidth
            >
              <Trans>Order</Trans>
            </Button>
          ) : (
            <Button variant="primary" disabled fullWidth>
              <Trans>Adding to the cart</Trans>
            </Button>
          )}
        </Flex>
      </Wrapper>
    );
  }
);

MobileSizeOverlay.displayName = "MobileSizeOverlay";

export default MobileSizeOverlay;
